import { ChangeEvent, useContext, useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import close from '../../../images/closeblack.svg'
import { GlobalContext } from "../../../context/GlobalContext"
import { Loader } from "../../../component/Loader"
import { BuyerItem, GroupItem } from "../../../classes"
import xlsx from "json-as-xlsx"

export const GroupItemPage = () => {
    const auth = useContext(GlobalContext) 
    const { pk } = useParams()
    const [openPopup, setOpenPopup] = useState(false)
    const [openPopupUser, setOpenPopupUser] = useState(false)
    const [load, setLoad] = useState(true)
    const [group, setGroup] = useState(new GroupItem({
        pk: Number(pk),
        title: "",
        desc: "",
        users: []
    }))
    const [form, setForm] = useState({
        title: "",
        desc: ""
    })

    const [formUser, setFormUser] = useState("")
 

  const downloadFile = () => {
    let content: [{}] = [{}]
    content.shift()
    group.users.forEach((item, index) => {
        content.push({
            id: item.pk,
            fio: `${item.surname} ${item.name} ${item.minName}`,
            phone: item.phone,
            email: item.email
        })
    })

    if(group.users.length){
        let data = [
            {
                sheet: "Adults",
                columns: [
                    { label: "ID", value: "id" },
                    { label: "ФИО", value: "fio" },
                    { label: "Номер телефона", value: "phone" },
                    { label: "Email", value: "email" },
                ],
                content: content!
            }, 
        ]
        let settings = {
        fileName: "Users",
        }
        xlsx(data, settings)
    }
  }
      
    const GetData = (page: number) => {
        setLoad(true)
        group.getData(auth.user.userToken).then(function(response){
            setLoad(false)


        }).catch(function(error){
            setLoad(false)
            alert("error")
        })
    }

    const DelUser = (user: number) => {
        group.delUser(auth.user.userToken, user).then(function(response){
            alert(response)
            GetData(1)
        }).catch(function(error){
            alert("error")
        })
    }

    useEffect(()=>{
        GetData(1)
        //downloadFile()
    },[])

    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }
    
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault()
        group.updateData(auth.user.userToken, form.title, form.desc).then(function(response){
            setOpenPopup(false)
            alert(response)
            GetData(1)
        }).catch(function(error){
            alert("error")
        })
    }
    
    const handleSubmitUser = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        group.setUser(auth.user.userToken, formUser).then(function(response){
            setFormUser("")
            setOpenPopupUser(false)
            alert(response)
            GetData(1)
        }).catch(function(error){
            alert("error")
        })
    }

    if(load){
        return(
            <Loader />
        )
    }
    
    return (
        <>
            <div className="content">
                <div className="user-add">
                    <div className="row">
                        <div className="col-md-12 right courses-desc__btn"> 
                            <Link to={`/group/`}>
                                {`< Назад`}
                            </Link>
                            <button style={{backgroundColor: "#317c50"}} onClick={()=>{
                                downloadFile()
                            }}>Выгрузить в excel</button>
                            <button style={{marginLeft: 15}} onClick={()=>{
                                setOpenPopupUser(true)
                            }}>Добавить пользователя</button>
                            <button onClick={()=>{
                                setForm({
                                    title: group.title,
                                    desc: group.desc
                                })
                                setOpenPopup(true)
                            }} style={{marginLeft: 15}}>Изменить группу</button>
                            <button onClick={()=>{
                                group.delData(auth.user.userToken).then(function(response){
                                    alert(response)
                                    window.location.replace('/group/')
                                }).catch(function(error){
                                    alert("error")
                                })
                            }} style={{backgroundColor: "red", marginLeft: 15}}>Удалить группу</button>
                        </div>
                    </div>
                </div>

                <div className={"group-info"}>
                    <h1>{group.title}</h1>
                    <p>{group.desc}</p>
                </div>

                <div className='table-list group__table-list'>
                    <div className='table-list__body'>
                        <table className="delivery">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>ФИО</th>
                                    <th>Номер телефона</th>
                                    <th>Email</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    group.users.length ? (
                                        group.users.map((item, index) => {
                                            return(
                                                <tr key={index}>
                                                    <td aria-label="ID">
                                                        <Link to={`/user/${item.pk}/`}>{item.pk}</Link>
                                                    </td>
                                                    <td aria-label="ФИО">{`${item.surname} ${item.name} ${item.minName}`}</td>
                                                    <td aria-label="Номер телефона">{item.phone}</td>
                                                    <td aria-label="Email">{item.email}</td>
                                                    <td className="right">
                                                        <button onClick={()=>{
                                                            DelUser(item.pk)
                                                        }}>Удалить</button>
                                                    </td>
                                                </tr> 
                                            )
                                        })

                                    ):""
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {
                openPopupUser ? (
                    <div className="popup-form mini">
                        <div>
                            <div className="popup-close">
                                <img src={close} onClick={()=>{setOpenPopupUser(false)}} />
                            </div>
                            <div className="adduser-form"> 
                                <form onSubmit={handleSubmitUser}>
                                    <label>
                                        Email пользователя <span>(Или несколько через ";")</span>
                                        <input required name="formUser" value={formUser} onChange={(e)=>{
                                            setFormUser(e.target.value)
                                        }} />
                                    </label>
                                    <button>Добавить</button>
                                </form>      
                            </div>
                        </div>
                    </div>
                ):""
            }

            {
                openPopup ? (
                    <div className="popup-form mini">
                        <div>
                            <div className="popup-close">
                                <img src={close} onClick={()=>{setOpenPopup(false)}} />
                            </div>
                            <div className="adduser-form"> 
                                <form onSubmit={handleSubmit}>
                                    <label>
                                        Название
                                        <input maxLength={255} required name="title" value={form.title} onChange={changeHandler} />
                                    </label>
                                    <label>
                                        Краткое описание
                                        <input maxLength={510} required name="desc" value={form.desc} onChange={changeHandler} />
                                    </label>
                                    <button>Создать</button>
                                </form>      
                            </div>
                        </div>
                    </div>
                ):""
            }
        </>
    )
}